import './WorkerPage.css';
import React, {Component} from 'react';

class WorkerPage extends Component {

    static defaultProps = {
        workerId: '',
    };

    state = {
        userName: '',
        password: '',
    };

    render() {
        return(
            <div className="Page">
                <text className="Poetry">古诗收录系统</text>
                <div className="Content">
                    {this._renderMenu()}
                    {this._renderForm()}
                </div>
            </div>
        );
    }

    _renderMenu() {
        return(
            <div className="MenuLayout">
                <text className="SubTitle">我的古诗</text>
            </div>
        );
    }

    _renderForm() {
        return(
            <div className="FormLayout">
                表单
            </div>
        );
    }
}

export default WorkerPage;
