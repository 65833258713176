import './Login.css';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

class LoginPage extends Component {

    static propTypes = {
        onLoginCallBack: PropTypes.func.isRequired,
    };

    state = {
        userName: '',
        password: ''
    };

    render() {
        return (
            <div className="Login">
                <text className="Poetry">古诗收录系统</text>
                <div className="DivName">
                    <text className="NameText">姓名</text>
                    <input className="InputName" type="text" value={this.state.userName} onChange={(e) => {
                        this.setState({userName: e.target.value});
                    }}/>
                </div>
                <div className="DivPassword">
                    <text className="PasswordText">密码</text>
                    <input className="InputPassword" type="text" onChange={(e) => {
                        this.setState({password: e.target.value});
                    }}/>
                </div>
                <button
                    className="Button"
                    onClick={(userName, password) => {
                        if (!this.state.userName) {
                            alert("用户名不可为空");
                            return;
                        }
                        if (!this.state.password) {
                            alert("密码不可为空");
                            return;
                        }
                        this.props.onLoginCallBack(this.state.userName, this.state.password);
                    }}
                >登录</button>
            </div>
        );
    }
}

export default LoginPage;
