import './App.css';
import React, {Component} from 'react';
import LoginPage from './login';
import WorkerPage from "./collection/WorkerPage";

const Status = {
    Logout: 1,  // 未登录
    Manger: 2,    // 管理
    Reviewer: 2,  // 审核
    Worker: 3    // 采集员
};

class App extends Component {

    state = {
        userName: '',
        password: '',
        status: Status.Logout,
    };

    render() {
        if (this.state.status === Status.Logout) {
            return <LoginPage onLoginCallBack={(userName, password) => this._login(userName, password)}/>;
        }
        if (this.state.status === Status.Manger) {
            return <LoginPage onLoginCallBack={(userName, password) => this._login(userName, password)}/>;
        }
        if (this.state.status === Status.Reviewer) {
            return <LoginPage onLoginCallBack={(userName, password) => this._login(userName, password)}/>;
        }
        if (this.state.status === Status.Worker) {
            return <WorkerPage/>;
        }
    }

    _login(userName, password) {
        const users = [
            {userName: '王宁', password: 'wn12345678'},
            {userName: '杜旭爱', password: 'du12345678'},
            {userName: '雷旭彪', password: '12345678'},
        ];
        // 输入的用户名是否在库中
        const arr = users.find((u) => u.userName === userName);
        if (arr) {
            if (arr.password === password) {
                if (userName === '王宁') {
                    this.setState({status: Status.Manger, userName: userName, password: password});
                } else if (userName === '杜旭爱') {
                    this.setState({status: Status.Reviewer, userName: userName, password: password});
                } else {
                    this.setState({status: Status.Worker, userName: userName, password: password});
                }
            } else {
                alert('密码错误');
            }
        } else {
            alert('未找到用户，请联系18600412701注册');
        }
    }


}

export default App;
